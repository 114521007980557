import SmokeLight from "@atoms/SmokeLight";
import TabItem from "@organism/Tabs/TabItem";
import Tabs from "@organism/Tabs/Tabs";
import React from "react";
import styles from "./service-tabs.module.scss";
import Tab01 from "../../../assets/image/tab01.png";
import Tab02 from "../../../assets/image/tab02.png";
import Tab03 from "../../../assets/image/tab03.png";
import DividerCenter from "@atoms/DividerCenter";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import ServicesTabsMobile from "@organism/ServicesTabsMobile";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { useTranslation } from "gatsby-plugin-react-i18next";
const smokeBlue =
  "radial-gradient(39.91% 42.1% at 46.34% 47.34%, rgba(16, 140, 255, 0.2) 0%, rgba(4, 6, 20, 0) 100%)";

const hide = {
  opacity: 0,
  y: 20,
};

const show = {
  opacity: 1,
  y: 0,
};

const transition = {
  type: "spring",
  stiffness: 20,
};

const ServiceTabs = () => {
  const [factoryRef, isFactoryVisible] = useOnScreen({ threshold: 0.1 });
  const { t } = useTranslation();
  const breakpoints = useBreakpoint();

  return (
    <section
      id='services'
      ref={factoryRef}
      className={`${styles.section} text-center ${styles.tabsContainer}`}
    >
      <div
        style={{
          position: "absolute",
          zIndex: -1,
          right: 0,
          bottom: 200,
          width: 700,
          overflow: "hidden",
        }}
      >
        <SmokeLight background={smokeBlue} width={1400} height={700} />
      </div>
      <motion.h2
        initial={hide}
        animate={isFactoryVisible ? show : hide}
        transition={transition}
        style={{ maxWidth: 880, margin: "0 auto" }}
      >
        {t("SERVICES.FACTORY.TITLE")}
      </motion.h2>
      <motion.p
        initial={hide}
        animate={isFactoryVisible ? show : hide}
        transition={{ ...transition }}
        className={styles.description}
      >
        {t("SERVICES.FACTORY.SUBTITLE")}
      </motion.p>
      {breakpoints.sm ? (
        <ServicesTabsMobile />
      ) : (
        <Tabs>
          <TabItem name={t("SERVICES.FACTORY.MANAGEMENT.TAB_NAME")} key='1'>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-5 m-auto'
            >
              <div className={styles.subtitleItem}>
                <h3>{t("SERVICES.FACTORY.MANAGEMENT.TITLE")}</h3>
                <p
                  className='lh28'
                  style={{ color: "rgba(255, 255, 255, 0.6)" }}
                >
                  {t("SERVICES.FACTORY.MANAGEMENT.SUBTITLE")}
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-7 text-center m-auto'
              style={{ minHeight: 441 }}
            >
              <img
                src={Tab01}
                className={`w-100 mw647 ${styles.tabImage}`}
                alt='Tab01'
                loading="lazy"
              />
            </motion.div>
          </TabItem>
          <TabItem name={t("SERVICES.FACTORY.SCOPE.TAB_NAME")} key='2'>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-5 m-auto'
            >
              <div className={styles.subtitleItem}>
                <h3>{t("SERVICES.FACTORY.SCOPE.TITLE")}</h3>
                <p
                  className='lh28'
                  style={{ color: "rgba(255, 255, 255, 0.6)" }}
                >
                  {t("SERVICES.FACTORY.SCOPE.SUBTITLE")}
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-7 text-center m-auto'
              style={{ minHeight: 441 }}
            >
              <img
                src={Tab02}
                className={`w-100 mw647 ${styles.tabImage}`}
                alt='Tab02'
                loading="lazy"
              />
            </motion.div>
          </TabItem>
          <TabItem name={t("SERVICES.FACTORY.PARTNER.TAB_NAME")} key='3'>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-5 m-auto'
            >
              <div className={styles.subtitleItem}>
                <h3>{t("SERVICES.FACTORY.PARTNER.TITLE")}</h3>
                <p
                  className='lh28'
                  style={{ color: "rgba(255, 255, 255, 0.6)" }}
                >
                  {t("SERVICES.FACTORY.PARTNER.SUBTITLE")}
                </p>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='col-md-7 text-center m-auto'
              style={{ minHeight: 441 }}
            >
              <img
                src={Tab03}
                className={`w-100 mw647 ${styles.tabImage}`}
                alt='Tab03'
                loading="lazy"
              />
            </motion.div>
          </TabItem>
        </Tabs>
      )}
      <DividerCenter />
    </section>
  );
};

export default ServiceTabs;
